<template>

	<div>

		<DIV class="zagolovok">Add/Edit Project</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabs" class="pr20 pl20">

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" id="name" class="required wp100" v-model="form.name" placeholder=" " autocomplete="off" :class="{'required--error':$v.form.name.$error}">
						<label for="name">Title</label>

					</div>

				</div>

				<div class="flex-container mt10 mb20 box--child">

					<label v-for="item in guides.markers" :key="item" class="flex-string wp15 relative infodiv dotted bgwhite text-center marker-select" :class="{'current':form.color === item}">
						<input type="radio" id="color" v-model="form.color" :value="item" class="hidden"><img :src="apiBaseURL + '/assets/images/map/location-'+ item + '.png'" height="30">
						<div class="fs-09 Bold">{{ item }}</div>
					</label>

				</div>

				<div class="flex-container mt10 mb5 box--child">

					<div class="flex-string wp100 relative material like-input pt20">

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input type="radio" v-model="form.active" :value="true">
								<span class="custom-radio"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold blue">Активен</span>
							</label>
						</div>

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input type="radio" v-model="form.active" :value="false">
								<span class="custom-radio alert"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold red">В архиве</span>
							</label>
						</div>

						<div class="label">Активность</div>

					</div>

				</div>

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" id="code" class="required wp100" v-model="form.code" placeholder=" " autocomplete="off" :class="{'required--error':$v.form.code.$error}">
						<label for="code">Code</label>

					</div>

				</div>

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative mt10 material like-input pt20" :class="{'dragover':over}" ref="dropzone" @dragover="over = true" @dragleave="over = false" @dragover.prevent @drop.stop.prevent="onDrop">

						<div class="filebox hidden">
							<input type="file" ref="file" class="file required wp100" @change="validatefile">
						</div>

						<a @click="$refs.file.click()" class="button m0 mb5 fs-07" id="selectFile"><i class="icon-plus-circled"></i>Выбрать</a>

						<span class="pl20">или перетащите сюда</span>

						<div class="infodiv bgwhite1 mt10" :class="{'hidden':logofile === ''}">
							<img :src="logofile" class="thumb-image" style="max-height:40px">
						</div>

						<div v-if="selectedFile" class="infodiv bgwhite1 mt10">
							Выбран файл <b class="blue">{{ selectedFile }}</b>
						</div>

						<!--					выбор нескольких файлов-->
						<div v-for="item in selectedFiles" :key="item" class="attention bgwhite mt10">
							{{ item }}
						</div>

						<div class="label">Логотип проекта</div>

						<div class="flex-string wp100 mt5 fs-07">
							Поддерживаются форматы: *.{{ allowed.join(", *.").toUpperCase() }}
						</div>

					</div>

				</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" id="url" class="wp100" v-model="form.url" placeholder=" " autocomplete="on">
						<label for="url">Сайт</label>

					</div>

				</div>

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative material like-input pt20">

						<div class="radio wp33 inline border-box p51 mr5 mb5 infodiv" :class="{'disabled':form.attachto !== 0}">
							<label class="middle">
								<input name="attachto" type="radio" v-model="form.attachto" :value="0">
								<span class="custom-radio hidden" :class="{'secondary':form.attachto === 0}"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold1 ellipsis gray-dark">Нет</span>
							</label>
						</div>

						<div v-for="item in projectList" :key="item.id" class="radio wp33 inline border-box p51 mr5 mb5 infodiv" :class="{'disabled':item.id !== form.attachto}" :title="item.name">
							<label class="middle">
								<input name="attachto" type="radio" v-model="form.attachto" :value="item.id">
								<span class="custom-radio hidden" :class="{'secondary':item.id !== form.attachto}"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold1 ellipsis" :class="{'Bold':item.id === form.attachto}">{{ item.name }}</span>
							</label>
						</div>

						<div class="label">Прикрепить к проекту</div>

					</div>

				</div>

				<div class="divider">Параметры записи на собеседования</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material">

						<input type="number" step="0.5" min="0" max="12" id="todayTimeScheduleAfter" class="wp100" v-model="form.todayTimeScheduleAfter" placeholder=" " autocomplete="on">
						<label for="todayTimeScheduleAfter">Запись через</label>

					</div>

					<div class="infodiv">
						Укажите множитель часов для расчета времени, через которое можно производить запись на сегодня от текущего момента.
						<ul>
							<li>0 - не записывать на сегодня</li>
							<li>0.5 - через 30 минут</li>
							<li>1 - через 60 минут</li>
						</ul>

					</div>

				</div>

				<div class="divider">Скрипт оператора</div>

				<div class="space-10"></div>

				<div class="flex-container mt10 box--child">

					<div class="flex-string wp100 relative material">

						<textarea v-model="form.script.url" id="scripturl" class="wp100" placeholder=" "></textarea>
						<label for="scripturl">Ссылка</label>

					</div>

				</div>

				<div class="flex-container mt10 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" v-model="form.script.name" id="scriptname" class="wp100" placeholder=" " autocomplete="on">
						<label for="scriptname">Название</label>

					</div>

				</div>

				<div class="mt10 box--child relative material like-input xbuttons">

					<div class="flex-container box--child">

						<div v-for="(item, index) in buttons" :key="index" class="flex-string wp15">
							<label class="relative flh-10 text-center" :class="{'current':item === form.script.color}">
								<input v-model="form.script.color" type="radio" :value="item" class="hidden">
								<a class="xbutton button mb5 mr5" :class="[{'disabled':item !== form.script.color},item]" title="Скрипт">Button</a>&nbsp;
							</label>
						</div>

					</div>
					<div class="label">Цвет</div>

				</div>

				<div class="space-20"></div>

				<div class="divider"><b class="red fs-12">Данные в СолвоПро</b></div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" name="solvopro.url" id="solvoprourl" class="wp100" v-model="form.solvopro.url" placeholder=" " autocomplete="on">
						<label for="solvoprourl">Адрес проекта</label>

					</div>

					<div class="flex-string wp100 mt10 mb20 relative material">
						<div class="divider">Для старого СолвоПро</div>
					</div>

					<div class="space-10"></div>

					<div class="flex-string wp100 relative material">

						<input type="text" id="solvoprouser" class="wp100" v-model="form.solvopro.user" placeholder=" " autocomplete="on">
						<label for="solvoprouser">Пользователь</label>

					</div>
					<div class="flex-string wp100 relative material">

						<input type="text" id="solvopropassword" class="wp100" v-model="form.solvopro.password" placeholder=" " autocomplete="on">
						<label for="solvopropassword">Пароль</label>

					</div>

					<div class="flex-string wp100 mt10 mb20 relative material">
						<div class="divider">Для нового СолвоПро</div>
					</div>

					<div class="space-10"></div>

					<div class="flex-string wp100 relative material">

						<input type="text" id="solvoprokey" class="wp100" v-model="form.solvopro.key" placeholder=" " autocomplete="on">
						<label for="solvoprokey">ApiKey</label>

					</div>

					<div class="flex-string wp100 relative material">

						<input type="text" id="solvoprovacancy" class="wp100" v-model="form.solvopro.vacancy" placeholder=" " autocomplete="on">
						<label for="solvoprovacancy">Коды вакансий</label>

					</div>

				</div>

				<div class="divider"><b class="red fs-12">Данные в Skillaz</b></div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" name="skillaz.token" id="token" class="wp100" v-model="form.skillaz.token" placeholder=" " autocomplete="on">
						<label for="token">Token</label>

					</div>

					<div class="flex-string wp100 relative material">

						<input type="text" id="skillazsource" class="wp100" v-model="form.skillaz.source" placeholder=" " autocomplete="on">
						<label for="skillazsource">Source</label>

					</div>
					<div class="flex-string wp100 relative material">

						<input type="text" id="skillaz.sfsource" class="wp100" v-model="form.skillaz.sfsource" placeholder=" " autocomplete="on">
						<label for="skillazsfsource">SfSource</label>

					</div>

				</div>

				<div class="divider"><b class="blue fs-12">Параметры для MyRest [KFC IRB]</b></div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="space-10"></div>

					<div class="flex-string wp100 relative material">

						<input type="text" id="myresturl" class="wp100" v-model="form.myrest.url" placeholder=" " autocomplete="on">
						<label for="myresturl">URL API</label>

					</div>

					<div class="flex-string wp100 relative material">

						<input type="text" id="myrestemail" class="wp100" v-model="form.myrest.email" placeholder=" " autocomplete="on">
						<label for="myrestemail">Email</label>

					</div>
					<div class="flex-string wp100 relative material">

						<input type="text" id="myrestpassword" class="wp100" v-model="form.myrest.password" placeholder=" " autocomplete="on">
						<label for="myrestpassword">Пароль</label>

					</div>

				</div>

				<div class="divider"><b class="blue fs-12">Параметры для Dialer</b></div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 mt10 mb20 relative material">

					</div>

					<div class="space-10"></div>

					<div class="flex-string wp100 relative material">

						<input type="text" id="dialercode" class="wp100" v-model="form.dialer.code" placeholder=" " autocomplete="on">
						<label for="dialercode">Код проекта</label>

					</div>

					<div class="flex-string wp100 relative material">

						<input type="text" id="dialerbucket" class="wp100" v-model="form.dialer.bucket" placeholder=" " autocomplete="on">
						<label for="dialerbucket">Бакет по недозвонам</label>

					</div>
					<div class="flex-string wp100 relative material">

						<input type="text" id="dialerbucketcode" class="wp100" v-model="form.dialer.bucketcode" placeholder=" " autocomplete="on">
						<label for="dialerbucketcode">Код бакета по недозвонам</label>

					</div>
					<div class="flex-string wp100 relative material">

						<input type="text" id="dialerprefix" class="wp100" v-model="form.dialer.prefix" placeholder=" " autocomplete="on">
						<label for="dialerprefix">Префикс по недозвонам</label>

					</div>

					<div class="flex-string wp100 relative material">

						<input type="text" id="dialerbucketmeet" class="wp100" v-model="form.dialer.bucketmeet" placeholder=" " autocomplete="on">
						<label for="dialerbucket">Бакет по встречам</label>

					</div>
					<div class="flex-string wp100 relative material">

						<input type="text" id="dialerbucketmeetcode" class="wp100" v-model="form.dialer.bucketmeetcode" placeholder=" " autocomplete="on">
						<label for="dialerbucketmeetcode">Код бакета по встречам</label>

					</div>
					<div class="flex-string wp100 relative material">

						<input type="text" id="dialerprefixmeet" class="wp100" v-model="form.dialer.prefixmeet" placeholder=" " autocomplete="on">
						<label for="dialerprefix">Префикс по встречам</label>

					</div>

				</div>

				<div class="flex-container mb20 box--child hidden">

					<div class="flex-string wp100 relative material text-center">

						<A href="javascript:void(0)" @click="getSysVacancy()" title="Проверить возможность записи" class="button greenbtn">
							<i class="icon-check"></i> Загрузить системные вакансии
						</A>

					</div>

				</div>

				<div class="space-100"></div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<A href="javascript:void(0)" @click="formSubmit" class="button m0">Save</A>
				<A href="javascript:void(0)" @click="closeForm" class="button graybtn">Cancel</A>

			</div>

		</form>

	</div>

</template>

<script>
import {required} from "vuelidate/lib/validators";
import axios from "axios";

export default {
	name: "ProjectsForm",
	props: {
		id: Number,
	},
	data() {
		return {
			form: {
				id: this.id,
				name: "",
				color: "red",
				active: true,
				code: "",
				logo: "",
				url: "",
				attachto: 0,
				todayTimeScheduleAfter: 0,
				script: {
					url:"",
					name:"",
					color:""
				},
				solvopro: {},
				skillaz: {},
				dialer: {},
				myrest: {},
				file: "",
			},
			guides: this.$store.state.guides,
			projectList: this.$store.state.guides.projects,
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: false,
			message: "",
			logofile: "",
			selectedFile: '',
			selectedFiles: [],
			over: false,
			allowed: ["jpeg", "jpg", "png", "webp", "gif"],
			buttons: ["bluebtn","graybtn","indigobtn","greenbtn","tealbtn","orangebtn","orangedarkbtn","fioletbtn","redbtn","reddarkbtn","brounbtn","limebtn","yellowbtn","olivebtn"]
		}
	},
	validations: {
		form: {
			code: {
				required,
				minValue: 1
			},
			name: {
				required,
				minValue: 1
			},
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		}
	},
	created() {
		this.loadCard()
	},
	methods: {
		loadCard() {

			if (this.card > 0) {

				//this.form = this.guides.projects

				for (let index in this.projectList) {

					if (parseInt(this.projectList[index].id) === this.card) {

						if(this.projectList[index].script === null){
							this.projectList[index].script = {
								url:"",
								name:"",
								color:""
							}
						}
						else if(this.projectList[index].script === undefined){
							this.projectList[index].script = {
								url:"",
								name:"",
								color:""
							}
						}

						if( this.projectList[index].script.length === 0 ){
							this.projectList[index].script = {
								url:"",
								name:"",
								color:""
							}
						}
						if( this.projectList[index].solvopro.length === 0 ){
							this.projectList[index].solvopro = {
								url:"",
								user:"",
								password:"",
								key:"",
								vacancy:""
							}
						}

						if(this.projectList[index].dialer === null){
							this.projectList[index].dialer = {
								code:"",
								bucket:"",
								bucketcode:"",
								prefix:"",
								bucketmeet:"",
								bucketmeetcode:"",
								prefixmeet:"",
							}
						}
						else if(this.projectList[index].dialer === undefined){
							this.projectList[index].dialer = {
								code:"",
								bucket:"",
								bucketcode:"",
								prefix:"",
								bucketmeet:"",
								bucketmeetcode:"",
								prefixmeet:"",
							}
						}

						if(this.projectList[index].myrest === null){
							this.projectList[index].myrest = {
								url:"",
								email:"",
								password:""
							}
						}
						else if(this.projectList[index].myrest === undefined){
							this.projectList[index].myrest = {
								url:"",
								email:"",
								password:""
							}
						}

						this.form = this.projectList[index]
						this.logofile = this.apiBaseURL + this.projectList[index].logofile
						this.$emit("loaded")
						this.loading = false

						break
					}

				}
			}

			this.$emit("loaded")
			this.loading = false

		},
		formSubmit() {

			this.$v.form.$touch()

			if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: "Не заполнены обязательные поля",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				// do your submit logic here
				this.submitStatus = 'PENDING'

				//console.log(this.$refs.file)

				this.form.file = this.$refs.file.files[0];

				//console.log(this.form.file)

				// отправка формы здесь
				let formData = new FormData();

				formData.append('action', "edit");
				formData.append('id', this.form.id);
				formData.append('file', this.form.file);
				formData.append('name', this.form.name);
				formData.append('color', this.form.color);
				formData.append('active', this.form.active);
				formData.append('code', this.form.code);
				formData.append('todayTimeScheduleAfter', this.form.todayTimeScheduleAfter);

				if(this.form.url !== "") {
					formData.append('url', this.form.url);
				}

				formData.append('attachto', this.form.attachto);

				//formData.append('solvopro', this.form.solvopro);
				formData.append('solvopro[url]', this.form.solvopro.url);
				formData.append('solvopro[user]', this.form.solvopro.user);
				formData.append('solvopro[password]', this.form.solvopro.password);
				formData.append('solvopro[key]', this.form.solvopro.key);
				formData.append('solvopro[vacancy]', this.form.solvopro.vacancy);

				formData.append('script[url]', this.form.script.url);
				formData.append('script[name]', this.form.script.name);
				formData.append('script[color]', this.form.script.color);

				formData.append('skillaz[token]', this.form.skillaz.token);
				formData.append('skillaz[source]', this.form.skillaz.source);
				formData.append('skillaz[sfsource]', this.form.skillaz.sfsource);

				formData.append('myrest[url]', this.form.myrest.url);
				formData.append('myrest[email]', this.form.myrest.email);
				formData.append('myrest[password]', this.form.myrest.password);

				formData.append('dialer[code]', this.form.dialer.code);
				formData.append('dialer[bucket]', this.form.dialer.bucket);
				formData.append('dialer[bucketcode]', this.form.dialer.bucketcode);
				formData.append('dialer[prefix]', this.form.dialer.prefix);
				formData.append('dialer[bucketmeet]', this.form.dialer.bucketmeet);
				formData.append('dialer[bucketmeetcode]', this.form.dialer.bucketmeetcode);
				formData.append('dialer[prefixmeet]', this.form.dialer.prefixmeet);

				formData.append('token', this.$store.state.token);

				//param['schedule'] = this.form.schedule

				axios.post(this.apiBaseURL + '/api/projects/', formData, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'multipart/form-data',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.message = data.data.result.result
							this.closeForm()
							this.$emit("update")

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						},
					)
					.catch(error => {
						console.log(error)

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

				//console.log(this.submitStatus)

			}

		},
		closeForm() {
			this.$emit("close")
		},
		clearInput(element) {
			this.form[element] = '';
		},
		validatefile() {

			//let file = this.$refs.file.files
			let filePath = this.$refs.file.value
			let fileName = this.$refs.file.files[0].name
			let extn = filePath.substring(filePath.lastIndexOf('.') + 1).toLowerCase()
			let slctd = []
			let self = this

			if (this.allowed.includes(extn)) {

				this.selectedFile = fileName

				/*let reader = new FileReader()
				reader.onload = function (e) {
					self.logofile = e.target.result
				}*/

				if (typeof (FileReader) !== undefined) {

					let countFiles = this.$refs.file.files.length;

					//loop for each file selected for uploaded.
					for (let i = 0; i < countFiles; i++) {

						let reader = new FileReader()

						reader.onload = function (e) {

							slctd.push(fileName)
							self.logofile = e.target.result

							//console.log(self.logofile)

						}

						reader.readAsDataURL(this.$refs.file.files[i])

					}
					//this.selectedFiles = slctd

				}
				else {

					this.$toast.error({
						title: "Attention!",
						message: "Упс, ваш браузер совсем не поддерживает технологию предпросмотра FileReader.",
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					});

				}

			} else {

				this.$toast.error({
					title: "Attention!",
					message: "Не допустимый формат файла",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				});

			}

		},
		onDrop(event) {

			let vm = this

			event.preventDefault();
			event.stopPropagation();

			this.over = false
			this.$refs.file.files = event.dataTransfer.files;

			//console.log(this.$refs.file.files)

			for (let i = 0; i < event.dataTransfer.files.length; i++) {
				let file = event.dataTransfer.files.item(i);
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = function (e) {
					vm.logofile = e.target.result
					vm.validatefile();
				};
			}

		},
		// не используется
		getSysVacancy(){

			let param = {}

			param['solvopro'] = {}

			param['action'] = 'sysvacancy'
			param['project'] = this.form.project
			param['solvopro']['key'] = this.form.solvopro.key
			param['solvopro']['url'] = this.form.solvopro.url

			this.loading = true

			axios.post(this.apiBaseURL + '/api/projects/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					this.loading = false

					if (!data.data.result) {
						this.$toast.warn({
							title: 'Записать не возможно',
							message: "Выбранный кандидат уже находится в обработке заказчика.\nПредложите другой проект!",
							position: "bottom center",
							timeOut: 30000,
							progressBar: true
						})
					} else {
						this.$toast.success({
							title: 'Можно записывать',
							message: "Можно записать кандидата!",
							position: "bottom center",
							timeOut: 15000,
							progressBar: true
						})
					}

				},
			).catch(error => {

				this.loading = false

				this.$toast.error({
					title: 'Error',
					message: error.response.status + ": " + error.response.statusText,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				console.log(error)

			})

		}
	}
}
</script>

<style scoped>
.marker-select:hover {
	background : var(--orange-lite);
}

.marker-select.current {
	background : var(--green-sublite);
}

label input[type="radio"]:checked {
	background : var(--green-sublite) !important;
}
.dragover {
	border: 3px dashed var(--blue) !important;
	background: var(--blueliter);
}

.xbuttons label{
	line-height: unset;
}

.infodiv.disabled{
	background-color: var(--gray-lite);
}
</style>
import { render, staticRenderFns } from "./VacancyImport.vue?vue&type=template&id=56b86866&scoped=true&"
import script from "./VacancyImport.vue?vue&type=script&lang=js&"
export * from "./VacancyImport.vue?vue&type=script&lang=js&"
import style0 from "./VacancyImport.vue?vue&type=style&index=0&id=56b86866&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56b86866",
  null
  
)

export default component.exports
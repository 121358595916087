<template>

	<div>

		<DIV class="zagolovok">Add/Edit HR Integration</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabse" class="pr10 pl10">

				<div class="flex-container mt10 mb10">

					<div class="flex-string wp100 material">

						<input type="text" v-model="form.name" id="name" class="wp100" placeholder=" " autocomplete="off">
						<label for="name">App Name</label>

					</div>

				</div>

				<div class="flex-container mt10 mb10">

					<div class="flex-string wp100 material">

						<input type="text" v-model="form.redirect_url" id="redirect_url" class="wp100" placeholder=" " autocomplete="off">
						<label for="redirect_url">Redirect URL</label>

					</div>

				</div>

				<div class="flex-container mt10 mb10">

					<div class="flex-string wp100 material">

						<input type="text" v-model="form.client_id" id="client_id" class="wp100" placeholder=" " autocomplete="off">
						<label for="client_id">Client ID</label>

					</div>

				</div>

				<div class="flex-container mt10 mb10">

					<div class="flex-string wp100 material">

						<input type="text" v-model="form.client_secret" id="client_secret" class="wp100" placeholder=" " autocomplete="off">
						<label for="client_secret">Client Secret</label>

					</div>

				</div>

				<div v-if="card === 'hhru'" class="flex-container mt10 mb10">

					<div class="flex-string wp100 material">

						<input type="text" v-model="form.access_token" id="access_token" class="wp100 linked" placeholder=" " autocomplete="off">
						<label for="access_token">Access Token</label>
						<a href="javascript:void(0)" @click="getAccessToken" data-for="access_token" class="linked" title="Получить новый">Получить новый</a>

					</div>

				</div>

				<div v-if="form.provider !== 'avito'">

					<div class="divider">Пользовательская авторизация</div>

					<div class="flex-container mt10 mb10 infodiv">

						<div class="flex-string wp100 material">

							Требуется пройти первоначальную пользовательскую авторизацию
							<a href="javascript:void(0)" @click="openOAuth()" data-for="user_token[access_token]" class="button greenbtn" title="Пройти авторизацию">OAuth</a>
							<a href="javascript:void(0)" v-if="form.provider === 'hhru'" @click="setWebHook" class="button redbtn" title="Получить новый">Установить Webhook</a>

						</div>

					</div>

				</div>
				<div v-else>

					<a href="javascript:void(0)" @click="setWebHook" class="button redbtn" title="Получить новый">Установить Webhook</a>

				</div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<A href="javascript:void(0)" @click="formSubmit" class="button m0">Save</A>
				<A href="javascript:void(0)" @click="closeForm" class="button graybtn">Cancel</A>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";
import {required} from "vuelidate/lib/validators";

export default {
	name: "HRIntegrationForm",
	components:{
	},
	props: {
		provider: String,
		// передается только для того, чтобы vue обновлял с сервера
		time: Object
	},
	validations: {
		form: {
			name: {
				required
			},
		}
	},
	data() {
		return {
			form: {
				name: "",
				redirect_url: "",
				client_id: "",
				client_secret: "",
				access_token: "",
			},
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: false,
			message: "",
			providers:{
				"hhru":"https://hh.ru/oauth/authorize?response_type=code&state=hhru&client_id=",
				"rbru":"https://api.rabota.ru/oauth/authorize.html?scope=profile,vacancies&display=page&app_id=",
				"zpru":"https://hr.zarplata.ru/oauth/authorize?response_type=code&scope=basic&client_id=",
				"avito":"https://avito.ru/oauth?response_type=code&scope=basic&client_id=",
			},
			fields: {
				"hhru":{
					client_id:"Client ID",
					client_secret:"Client Secret"
				},
				"rbru":{
					client_id:"Client ID",
					client_secret:"Client Secret"
				},
				"zpru":{
					client_id:"Client ID",
					client_secret:"Client Secret"
				},
				"spj":{
					client_id:"ID приложения",
					client_secret:"Secret Key"
				}
			}
		}
	},
	computed: {
		card() {
			return this.provider !== '' ? this.provider : ""
		},
	},
	created() {
		this.loadCard()
	},
	watch: {
		time(){
			this.loadCard()
		}
	},
	methods: {
		loadCard() {

			if(this.card !== '') {

				this.loading = true

				let param = {};

				param['provider'] = this.card
				param['action'] = 'info'

				axios.post(this.apiBaseURL + '/api/hrintegration/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							let rez = data.data.result

							this.form.provider = rez.provider
							this.form.name = rez.name
							this.form.redirect_url = rez.redirect_url
							this.form.client_id = rez.client_id
							this.form.client_secret = rez.client_secret
							this.form.access_token = rez.access_token

							/*this.providers = {
								"hhru":"https://hh.ru/oauth/authorize?response_type=code&state=hhru&client_id=" + this.form.client_id + '&redirect_uri=' + this.form.redirect_url,
								"rbru":"https://api.rabota.ru/oauth/authorize.html?scope=profile,vacancies&display=page&app_id=" + this.form.client_id + '&redirect_uri=' + this.form.redirect_url,
								"zpru":"https://auth.zarplata.ru/v1/oauth/authorize?response_type=code&scope=basic&client_id=" + this.form.client_id + '&redirect_uri=' + this.form.redirect_url,
							}*/

						},
					)
					.catch(error => {
						console.log(error)
					})

			}

			this.$emit("loaded")
			this.loading = false

		},
		formSubmit() {

			this.$v.form.$touch()

			if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: "Не заполнены обязательные поля",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				let param = {}

				param = this.form
				param['action'] = 'edit'
				param['provider'] = this.card

				axios.post(this.apiBaseURL + '/api/hrintegration/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.message = data.data.result.result
							this.$emit("update")
							this.closeForm()

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						},
					)
					.catch(error => {
						console.log(error)

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

			}

		},
		closeForm() {
			this.form = {
				name: "",
				redirect_url: "",
				client_id: "",
				client_secret: "",
				access_token: "",
			}
			this.$emit("close")
		},
		openOAuth(){

			let url = this.providers[this.form.provider] + this.form.client_id + '&redirect_uri=' + this.form.redirect_url;

			window.open(url, 'CRM', 'width=500, height=500, menubar=no, location=no, resizable=no, scrollbars=yes, status=no');

		},
		getAccessToken(){

			let param = {};
			param['action'] = 'access_token'
			param['provider'] = this.card
			param['client_id'] = this.form.client_id
			param['client_secret'] = this.form.client_secret

			axios.post(this.apiBaseURL + '/api/hrintegration/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.form.access_token = data.data.result.access_token

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		getKey(field){

			let param = {};
			param['action'] = 'generator'
			param['field'] = field

			axios.post(this.apiBaseURL + '/api/integration/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.form[field] = data.data.result

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		setWebHook(){

			let param = {};

			param['action'] = 'avitoWebhook'

			if( this.provider === 'hhru' ){
				param['action'] = 'hhruWebhook'
			}

			param['provider'] = this.card
			param['client_id'] = this.form.client_id
			param['client_secret'] = this.form.client_secret

			axios.post(this.apiBaseURL + '/api/hrintegration/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						if(data.data.code === 200){

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						}

					},
				)
				.catch(error => {
					this.$toast.error({
						title: 'Error',
						message: error.error,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

		}
	}
}
</script>

<style scoped>

</style>
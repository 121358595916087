<template>

	<div>

		<DIV class="zagolovok">Шаблоны для Chat2Desk (отправка через 3 часа после встречи)</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabse" class="pr10 pl10">

				<div class="flex-container mt10">

					<div class="flex-string wp100 material">

						<div class="relative">

							<div class="pull-aright mt10" v-click-outside="hideTagsmenu">

								<a href="javascript:void(0)" @click="showTagsmenu" title="Действия" class="tagsmenuToggler"><b class="blue">Вставить тэг</b>&nbsp;<i class="icon-angle-down" id="mapii"></i></a>
								<div class="tagsmenu" :class="{'show':tagsmenu}" style="right:0;">
									<ul style="max-height: 180px; z-index:200">
										<li @click="textAtCursor('{{soiskatel}}')" data-title="soiskatel"><b>ФИО кандидата</b></li>
										<li @click="textAtCursor('{{soiskatelsurname}}')" data-title="soiskatelsurname"><b>Фамилия кандидата</b></li>
										<li @click="textAtCursor('{{soiskatelname}}')" data-title="soiskatelname"><b>Имя кандидата</b></li>
										<li @click="textAtCursor('{{soiskatelpatronymic}}')" data-title="soiskatelpatronymic"><b>Отчество кандидата</b></li>
										<li @click="textAtCursor('{{phone}}')" data-title="phone"><b>Телефон кандидата</b></li>
										<li @click="textAtCursor('{{email}}')" data-title="email"><b>Email кандидата</b></li>
										<li @click="textAtCursor('{{addressName}}')" data-title="addressName"><b>Название объекта</b></li>
										<li @click="textAtCursor('{{address}}')" data-title="address"><b>Адрес объекта</b></li>
										<li @click="textAtCursor('{{date}}')" data-title="date"><b>Дата собеседования</b></li>
										<li @click="textAtCursor('{{time}}')" data-title="time"><b>Время собеседования</b></li>
										<li @click="textAtCursor('{{time_to_hour}}')" data-title="time_to_hour"><b>Время собеседования +1 час</b></li>
										<li @click="textAtCursor('{{time_to}}')" data-title="time_to"><b>Время собеседования ДО</b></li>
										<li @click="textAtCursor('{{company}}')" data-title="company"><b>Компания:кратко</b></li>
										<li @click="textAtCursor('{{company_full}}')" data-title="company_full"><b>Компания:полное</b></li>
										<li @click="textAtCursor('{{company_site}}')" data-title="company_site"><b>Сайт компании</b></li>
										<li @click="textAtCursor('{{company_address}}')" data-title="company_address"><b>Почтовый адрес</b></li>
										<li @click="textAtCursor('{{company_phone}}')" data-title="company_phone"><b>Телефон</b></li>
										<li @click="textAtCursor('{{company_mail}}')" data-title="company_mail"><b>Email</b></li>
									</ul>
								</div>

							</div>

							<textarea
								class="wp100"
								id="content"
								placeholder=" "
								ref="description"
								v-model="form.content"
								v-html="form.content"
								spellcheck="false"
							/>

						</div>

					</div>
					<div class="infodiv wp100">Разделитель вариантов: <div class="p3 inline orangebg">=======</div></div>

				</div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<A href="javascript:void(0)" @click="formSubmit" class="button m0">Save</A>
				<A href="javascript:void(0)" @click="closeForm" class="button graybtn">Cancel</A>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";
import {required} from "vuelidate/lib/validators";

export default {
	name: "Templates3HForm",
	props: {
		id: Number,
		// передается только для того, чтобы vue обновлял с сервера
		time: Object
	},
	validations: {
		form: {
			content: {
				required
			},
		}
	},
	data() {
		return {
			form: {
				content: "",
			},
			apiBaseURL: this.$store.getters.apiBaseURL,
			guides: this.$store.state.guides,
			loading: false,
			message: "",
			tagsmenu: false,
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
	},
	created() {
		this.loadCard()
	},
	watch: {
		time(){
			this.loadCard()
		}
	},
	methods: {
		loadCard() {

			this.loading = true

			let param = {};

			param['id'] = this.card
			param['action'] = 'templates3H'

			axios.post(this.apiBaseURL + '/api/smstemplates/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						let rez = data.data.result

						this.form.content = rez

					},
				)
				.catch(error => {
					console.log(error)
				})

			this.$emit("loaded")
			this.loading = false

		},
		formSubmit() {

			this.$v.form.$touch()

			if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: "Не заполнены обязательные поля",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				let param = {}

				param = this.form
				param['action'] = 'templates3Hedit'

				axios.post(this.apiBaseURL + '/api/smstemplates/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.message = data.data.result.result
							this.$emit("update")
							this.closeForm()

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						},
					)
					.catch(error => {
						console.log(error)

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

			}

		},
		closeForm() {
			this.$emit("close")
		},
		showTagsmenu(){
			this.tagsmenu = !this.tagsmenu
		},
		hideTagsmenu(){
			this.tagsmenu = false
		},
		textAtCursor(_text){

			let area = this.$refs.description
			let self = this

			if ((area.selectionStart) || (area.selectionStart == '0')) {

				let p_start = area.selectionStart;
				let p_end = area.selectionEnd;

				area.value = area.value.substring(0, p_start) + _text + area.value.substring(p_end, area.value.length);

			}

			if (area.selection) {

				area.focus();
				let sel = area.selection.createRange();
				sel.text = _text;

			}

			self.form.content = area.value
			self.tagsmenu = false

		},
	}
}
</script>

<style scoped>
textarea{
	height: 350px;
	padding-top: 40px;
}
</style>
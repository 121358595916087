const state = {
	feedback: {
		//items: {},
		page: 1,
		total: 1,
		pageall: 1
	},
	filters: {
		project: [],
		feedbacks: {
			content: '',
			phone: '',
			city: '',
			blank: '',
			finished: '',
			channel: [],
			users: [],
			results: []
		},
		sort: "date_create",
		order: "desc"
	},
};

const mutations = {
	// address
	SET_FILTER_PROJECTS (state, projects) {
		state.filters.project = projects
	},

	SET_FILTER (state, feedbacks) {
		state.filters.feedbacks = feedbacks
	},

	SET_FILTER_ORDER (state, order) {
		state.filters.order = order
	},

	SET_FILTER_SORT (state, sort) {
		state.filters.sort = sort
	},

	SET_CLEAN_FILTERS (state) {
		state.filters = {
			project: [],
			feedbacks: {
				content: '',
				phone: '',
				city: '',
				blank: '',
				finished: '',
				channel: [],
				users: [],
				results: []
			},
			sort: "date_create",
			order: "desc"
		}
	},

	SET_FEEDBACK(state, payload) {
		//state.feedback.items = payload.list
		state.feedback.page = payload.page
		state.feedback.pageall = payload.pageall
		state.feedback.total = payload.total
	},
	SET_FILTERS(state, payload) {
		state.filters = payload
	},

	CHANGE_PAGE(state, page) {
		state.feedback.page = page
	},
};

export default {
	namespaced: true,
	state,
	mutations
}